import _Vue from "vue";
import axios, { AxiosError, AxiosResponse } from "axios";

import { ResponseError, User } from "@/interfaces";
import authHeader from "@/services/auth/auth-header";

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL || window.location.origin + "/api"
});

export class APIService {
    async execute<T>(
        method: "get" | "post" | "put" | "delete",
        resource: string,
        // eslint-disable-next-line
        data: any | User | null = null
    ) {
        return client
            .request<T>({
                method,
                url: resource,
                data,
                headers: {
                    ...authHeader()
                }
            })
            .then((res: AxiosResponse) => {
                return res.data;
            })
            .catch((e: AxiosError) => {
                console.error(e);

                const errorData = e.response?.data as ResponseError;
                const txt = errorData ? errorData.detail : e.message;

                throw new Error(txt);
            }) as Promise<T>;
    }

    checkServer<T>() {
        return this.execute<T>("get", `/common/check`);
    }

    getBoxes<T>(client) {
        return this.execute<T>("get", `/common/boxes?client=${client}`);
    }

    datesUpdates<T>(date, period) {
        return this.execute<T>(
            "get",
            `/common/dates-updates?date=${date}&period=${period}`
        );
    }

    getAnalyzers<T>(box) {
        return this.execute<T>("get", `/common/analyzers?box=${box}`);
    }

    getElements<T>(box, analyzer) {
        if (analyzer) {
            return this.execute<T>(
                "get",
                `/common/elements?box=${box}&analyzer=${analyzer}`
            );
        } else {
            return this.execute<T>("get", `/common/elements?box=${box}`);
        }
    }

    getBoxBoundDates<T>(box, analyzer, element) {
        if (analyzer) {
            return this.execute<T>(
                "get",
                `/common/box-dates?box=${box}&analyzer=${analyzer}&element=${element}`
            );
        } else {
            return this.execute<T>(
                "get",
                `/common/box-dates?box=${box}&element=${element}`
            );
        }
    }

    downloadCSV<T>(box, analyzer, element, start, end) {
        if (analyzer) {
            return this.execute<T>(
                "get",
                `/common/download-csv?box=${box}&analyzer=${analyzer}&element=${element}&start=${start}&end=${end}`
            );
        } else {
            return this.execute<T>(
                "get",
                `/common/download-csv?box=${box}&element=${element}&start=${start}&end=${end}`
            );
        }
    }

    createUser<T>(user: User) {
        return this.execute<T>("post", "/user/create-user", user);
    }

    updateUser<T>(user: User) {
        return this.execute<T>("post", "/user/update-user", user);
    }

    getUsers<T>() {
        return this.execute<T>("get", `/user/get-users`);
    }

    deleteUser<T>(user: User) {
        return this.execute<T>("post", "/user/delete-user", user);
    }

    getEnergyTotal<T>(start, end, window, aggregation, installation) {
        if (window && aggregation) {
            return this.execute<T>(
                "get",
                `/energy/energy-total?start=${start}&end=${end}&window=${window}&aggregation=${aggregation}&installation=${installation}`
            );
        } else {
            return this.execute<T>(
                "get",
                `/energy/energy-total?start=${start}&end=${end}`
            );
        }
    }

    getActiveEnergy<T>(
        box,
        analyzer,
        element,
        start,
        end,
        window,
        aggregation
    ) {
        if (analyzer) {
            if (window && aggregation) {
                return this.execute<T>(
                    "get",
                    `/energy/active-energy?box=${box}&analyzer=${analyzer}&element=${element}&start=${start}&end=${end}&window=${window}&aggregation=${aggregation}`
                );
            } else {
                return this.execute<T>(
                    "get",
                    `/energy/active-energy?box=${box}&analyzer=${analyzer}&element=${element}&start=${start}&end=${end}`
                );
            }
        } else {
            if (window && aggregation) {
                return this.execute<T>(
                    "get",
                    `/energy/active-energy?box=${box}&element=${element}&start=${start}&end=${end}&window=${window}&aggregation=${aggregation}`
                );
            } else {
                return this.execute<T>(
                    "get",
                    `/energy/active-energy?box=${box}&element=${element}&start=${start}&end=${end}`
                );
            }
        }
    }

    getActiveEnergyComparition<T>(
        start,
        end,
        window,
        aggregation,
        installation
    ) {
        if (window && aggregation) {
            return this.execute<T>(
                "get",
                `/energy/comparative-energy?start=${start}&end=${end}&window=${window}&aggregation=${aggregation}&installation=${installation}`
            );
        } else {
            return this.execute<T>(
                "get",
                `/energy/comparative-energy?start=${start}&end=${end}`
            );
        }
    }

    getEnergyHoriz<T>(start, end, window, installation, data, comp, hijo) {
        if (window) {
            return this.execute<T>(
                "post",
                `/energy/energy-horiz?start=${start}&end=${end}&window=${window}&installation=${installation}&comp=${comp}&noChild=${hijo}`,
                data
            );
        } else {
            return this.execute<T>(
                "post",
                `/energy/energy-horiz?start=${start}&end=${end}`,
                data
            );
        }
    }

    consumeGeneration<T>(start, end, window, installation, data, comp, hijo) {
        if (window) {
            return this.execute<T>(
                "post",
                `/energy/consumos-generacion?start=${start}&end=${end}&window=${window}&installation=${installation}&comp=${comp}&noChild=${hijo}`,
                data
            );
        } else {
            return this.execute<T>(
                "post",
                `/energy/consumos-generacion?start=${start}&end=${end}`,
                data
            );
        }
    }

    consumeServices<T>(start, end, window, comp, hijo, data) {
        if (window) {
            return this.execute<T>(
                "post",
                `/energy/services?start=${start}&end=${end}&window=${window}&comp=${comp}&noChild=${hijo}`,
                data
            );
        } else {
            return this.execute<T>(
                "post",
                `/energy/services?start=${start}&end=${end}`,
                data
            );
        }
    }

    CSVconsumos<T>(start, end, window, comp, data) {
        if (window) {
            return this.execute<T>(
                "post",
                `/energy/gen-CSV?start=${start}&end=${end}&window=${window}&comp=${comp}`,
                data
            );
        } else {
            return this.execute<T>(
                "post",
                `/energy/gen-CSV?start=${start}&end=${end}`,
                data
            );
        }
    }

    SQLuserClient<T>(identifier) {
        return this.execute<T>("get", `/common/user?identifier=${identifier}`);
    }

    SQLstorageInstallations<T>(client: null | number = null) {
        if (client) {
            return this.execute<T>(
                "get",
                `/common/storageInstallation?client=${client}`
            );
        } else {
            return this.execute<T>("get", `/common/storageInstallation`);
        }
    }

    SQLstorageElements<T>(client: null | number = null) {
        if (client) {
            return this.execute<T>(
                "get",
                `/common/storageElement?client=${client}`
            );
        } else {
            return this.execute<T>("get", `/common/storageElement`);
        }
    }

    SQLstorageUbications<T>(client: null | number = null) {
        if (client) {
            return this.execute<T>(
                "get",
                `/common/storageUbications?client=${client}`
            );
        } else {
            return this.execute<T>("get", `/common/storageUbications`);
        }
    }

    SQLcompany<T>() {
        return this.execute<T>("get", `/common/company`);
    }

    SQLclient<T>(company) {
        return this.execute<T>("get", `/common/client?company=${company}`);
    }

    SQLelementoAhorro<T>(client) {
        return this.execute<T>(
            "get",
            `/common/elemento-ahorro?client=${client}`
        );
    }

    SQLelementoAhorroService<T>(client) {
        return this.execute<T>(
            "get",
            `/common/elemento-ahorro-service?client=${client}`
        );
    }

    SQLelementoService<T>(installation, withChild = false, withComp = false) {
        return this.execute<T>(
            "get",
            `/common/service-element?installation=${installation}&withChild=${withChild}&withComp=${withComp}`
        );
    }

    SQLelementoDatadis<T>(client) {
        return this.execute<T>("get", `/datadis/elemento?client=${client}`);
    }

    SQLinstallation<T>(client) {
        return this.execute<T>("get", `/common/installation?client=${client}`);
    }

    SQLall<T>(installation, withChild = false) {
        return this.execute<T>(
            "get",
            `/common/all?installation=${installation}&withChild=${withChild}`
        );
    }

    SQLallgen<T>(installation, withChild = false, withComp = false) {
        return this.execute<T>(
            "get",
            `/common/all-gen?installation=${installation}&withChild=${withChild}&withComp=${withComp}`
        );
    }

    SQLallgenserv<T>(installation) {
        return this.execute<T>(
            "get",
            `/algebra/all?installation=${installation}`
        );
    }

    saveAlgebra<T>(data) {
        return this.execute<T>("post", `/algebra/save`, data);
    }

    getAlgebra<T>(client) {
        return this.execute<T>("get", `/algebra/get-var?client=${client}`);
    }

    deleteAlgebra<T>(varCal) {
        return this.execute<T>("delete", `/algebra/delete?varCal=${varCal}`);
    }

    SQLmodbus<T>(installation) {
        return this.execute<T>(
            "get",
            `/common/modbus?installation=${installation}`
        );
    }

    SQLbox<T>(installation, element) {
        return this.execute<T>(
            "get",
            `/common/box?installation=${installation}&element=${element}`
        );
    }

    SQLanalyzer<T>(installation, element) {
        return this.execute<T>(
            "get",
            `/common/analyzer?installation=${installation}&element=${element}`
        );
    }

    SQLelement<T>(installation, element) {
        return this.execute<T>(
            "get",
            `/common/elemento?installation=${installation}&element=${element}`
        );
    }
    /*
Request de la page de resumen
*/
    getAlarms<T>(client, installation, element, start, end) {
        return this.execute<T>(
            "get",
            `/resume/alarms?client=${client}&installation=${installation}&element=${element}&start=${start}&end=${end}`
        );
    }

    getServices<T>() {
        return this.execute<T>("get", `/prices/getservices`);
    }

    getServicesInstall<T>(installation) {
        return this.execute<T>(
            "get",
            `/prices/getServicesInstall?installation=${installation}`
        );
    }

    getConsDia<T>(installation, start, end, period) {
        if (period) {
            return this.execute<T>(
                "get",
                `/consdia/consdia?installation=${installation}&start=${start}&end=${end}&period=${period}`
            );
        } else {
            return this.execute<T>(
                "get",
                `/consdia/consdia?installation=${installation}&start=${start}&end=${end}`
            );
        }
    }

    getPrecPers<T>(start, end, window, elements, Pmodo) {
        return this.execute<T>(
            "post",
            `/prices/precpers?start=${start}&end=${end}&window=${window}&Pmodo=${Pmodo}`,
            elements
        );
    }

    getPrecServ<T>(start, end, window, Pmodo, elements) {
        return this.execute<T>(
            "post",
            `/prices/services?start=${start}&end=${end}&window=${window}&Pmodo=${Pmodo}`,
            elements
        );
    }

    getPrices<T>(installation) {
        return this.execute<T>(
            "get",
            `/prices/getprices?installation=${installation}`
        );
    }

    savePrices<T>(client, installation, tipo, data) {
        return this.execute<T>(
            "post",
            `/prices/save?tipo=${tipo}&installation=${installation}&client=${client}`,
            data
        );
    }

    updatePrice<T>(precio, data, installation) {
        return this.execute<T>(
            "post",
            `/prices/update?price=${precio}&installation=${installation}`,
            data
        );
    }

    deletePrice<T>(Nprice, installation) {
        return this.execute<T>(
            "delete",
            `/prices/deleteprice?price=${Nprice}&installation=${installation}`
        );
    }

    saveProyect<T>(client, Pmodo, data) {
        return this.execute<T>(
            "post",
            `/report/save-project?client=${client}&Pmodo=${Pmodo}`,
            data
        );
    }

    getProject<T>(client) {
        return this.execute<T>("get", `/report/getProject?client=${client}`);
    }

    getMean<T>(installation, period, Pmodo, data) {
        return this.execute<T>(
            "post",
            `/report/mean?installation=${installation}&periodo=${period}&Pmodo=${Pmodo}`,
            data
        );
    }

    getMeanServices<T>(installation, period, Pmodo, data) {
        return this.execute<T>(
            "post",
            `/report/mean-service?installation=${installation}&periodo=${period}&Pmodo=${Pmodo}`,
            data
        );
    }

    getProjectLine<T>(project) {
        return this.execute<T>(
            "get",
            `/report/getProjectLine?project=${project}`
        );
    }

    getProjectLineService<T>(project) {
        return this.execute<T>(
            "get",
            `/report/getProjectLine-service?project=${project}`
        );
    }

    deleteProjectLine<T>(project) {
        return this.execute<T>(
            "delete",
            `/report/delete-project-line?project=${project}`
        );
    }

    deleteProject<T>(project, Nrevisio) {
        return this.execute<T>(
            "delete",
            `/report/delete-project?project=${project}&Nrevisio=${Nrevisio}`
        );
    }

    updateProjectLine<T>(project, data) {
        return this.execute<T>(
            "post",
            `/report/update-line-project?project=${project}`,
            data
        );
    }

    generateProject<T>(install, Nrevisio) {
        return this.execute<T>(
            "get",
            `/report/report-gen?installation=${install}&Nrevisio=${Nrevisio}`
        );
    }

    summary<T>(Ncliente, Pmodo, elements) {
        return this.execute<T>(
            "post",
            `/summary/summary?client=${Ncliente}&Pmodo=${Pmodo}`,
            elements
        );
    }

    summaryV2<T>(Ncliente, Pmodo, elements) {
        return this.execute<T>(
            "post",
            `/summary/v2/summary?client=${Ncliente}&Pmodo=${Pmodo}`,
            elements
        );
    }

    recapEnergy<T>(start, end, comp, elements, chart: any = null) {
        if (chart) {
            return this.execute<T>(
                "post",
                `/resume/recapConsumo?start=${start}&end=${end}&comp=${comp}&chart=${chart}`,
                elements
            );
        } else {
            return this.execute<T>(
                "post",
                `/resume/recapConsumo?start=${start}&end=${end}&comp=${comp}`,
                elements
            );
        }
    }

    recap<T>(start, end, comp, tag, elements, chart: any = null) {
        if (chart) {
            return this.execute<T>(
                "post",
                `/resume/recap?start=${start}&end=${end}&tag=${tag}&comp=${comp}&chart=${chart}`,
                elements
            );
        } else {
            return this.execute<T>(
                "post",
                `/resume/recap?start=${start}&end=${end}&tag=${tag}&comp=${comp}`,
                elements
            );
        }
    }

    recapServices<T>(start, end, comp, elements) {
        return this.execute<T>(
            "post",
            `/resume/recap-servicios?start=${start}&end=${end}&comp=${comp}`,
            elements
        );
    }

    recapAlarm<T>(start, end, elements) {
        return this.execute<T>(
            "post",
            `/resume/recapAlarm?start=${start}&end=${end}`,
            elements
        );
    }

    recapAlarmStandar<T>(start, end, elements) {
        return this.execute<T>(
            "post",
            `/resume/recapAlarm-Standar?start=${start}&end=${end}`,
            elements
        );
    }

    recapAlarmInstant<T>(start, end, elements) {
        return this.execute<T>(
            "post",
            `/resume/recapAlarm-Instant?start=${start}&end=${end}`,
            elements
        );
    }

    recapAlarmDiarias<T>(start, end, elements) {
        return this.execute<T>(
            "post",
            `/resume/recapAlarm-Diarias?start=${start}&end=${end}`,
            elements
        );
    }

    listAlarm<T>(start, end, elements) {
        return this.execute<T>(
            "post",
            `/alarm/listAlarm?start=${start}&end=${end}`,
            elements
        );
    }

    recapInformes<T>(start, end, elements) {
        return this.execute<T>(
            "post",
            `/resume/informes?start=${start}&end=${end}`,
            elements
        );
    }

    synchronDatadis<T>(user, password, Nempresa, Ncliente, Ninstala) {
        return this.execute<T>(
            "get",
            `/datadis/synchronize?user=${user}&password=${password}&Nempresa=${Nempresa}&Ncliente=${Ncliente}&Ninstala=${Ninstala}`
        );
    }

    verifyDatadis<T>(Ncliente) {
        return this.execute<T>("get", `/datadis/verify?Ncliente=${Ncliente}`);
    }

    getOffBox<T>() {
        return this.execute<T>("get", `/common/offBox`);
    }

    getLogs<T>() {
        return this.execute<T>("get", `/common/logs`);
    }

    getMeterStatus<T>() {
        return this.execute<T>("get", `/alarm/meterStatus`);
    }

    getHistory<T>(box) {
        return this.execute<T>("get", `/common/history?box=${box}`);
    }

    getAlarmsIns<T>() {
        return this.execute<T>("get", `/alarm/get_today`);
    }

    predictionTest<T>(parametro1, parametro2) {
        return this.execute<T>(
            "get",
            `/prediction/test?parametro1=${parametro1}&parametro2=${parametro2}`
        );
    }

    getMeasuresElement<T>(
        box,
        analyzer,
        element,
        start,
        end,
        window,
        aggregation,
        install
    ) {
        if (analyzer) {
            if (window && aggregation) {
                return this.execute<T>(
                    "get",
                    `/detailed/stream?box=${box}&analyzer=${analyzer}&element=${element}&start=${start}&end=${end}&window=${window}&aggregation=${aggregation}&install=${install}`
                );
            } else {
                return this.execute<T>(
                    "get",
                    `/detailed/stream?box=${box}&analyzer=${analyzer}&element=${element}&start=${start}&end=${end}&install=${install}`
                );
            }
        } else {
            if (window && aggregation) {
                return this.execute<T>(
                    "get",
                    `/detailed/stream?box=${box}&element=${element}&start=${start}&end=${end}&window=${window}&aggregation=${aggregation}&install=${install}`
                );
            } else {
                return this.execute<T>(
                    "get",
                    `/detailed/stream?box=${box}&element=${element}&start=${start}&end=${end}&install=${install}`
                );
            }
        }
    }

    //Wizard creación de cajas elementos "Admin SAT"

    getClientSAT<T>(company) {
        return this.execute<T>(
            "get",
            `/adminSAT/getClientSAT?company=${company}`
        );
    }
    getInstallationSAT<T>(client) {
        return this.execute<T>(
            "get",
            `/adminSAT/getInstallationSAT?client=${client}`
        );
    }

    getTimeZone<T>() {
        return this.execute<T>("get", `/adminSAT/getTimeZone`);
    }

    getFormularyInit<T>() {
        return this.execute<T>("get", `/adminSAT/formularyInit`);
    }

    getFormulary<T>() {
        return this.execute<T>("get", `/adminSAT/getFormulary`);
    }

    getBoxesSAT<T>(suiche, filter) {
        return this.execute<T>(
            "post",
            `/adminSAT/getBoxes?mode=${suiche}`,
            filter
        );
    }

    getBoxesSATInit<T>() {
        return this.execute<T>("get", `/adminSAT/getBoxesInit`);
    }

    deleteBoxSAT<T>(Ncaja) {
        return this.execute<T>("get", `/adminSAT/deleteBox?Ncaja=${Ncaja}`);
    }

    modifyBoxSAT<T>(box) {
        return this.execute<T>("post", `/adminSAT/modifyBox`, box);
    }

    addBoxSAT<T>(box) {
        return this.execute<T>("post", `/adminSAT/addBox`, box);
    }
    //

    getFamiTip<T>() {
        return this.execute<T>("get", `/adminSAT/getFamiTip`);
    }

    updateFTP<T>() {
        return this.execute<T>("get", `/adminSAT/updateFTP`);
    }

    getAlaTip<T>(Nfamilia) {
        return this.execute<T>(
            "get",
            `/adminSAT/getAlaTip?Nfamilia=${Nfamilia}`
        );
    }

    getElementsSAT<T>(suiche, filter) {
        return this.execute<T>(
            "post",
            `/adminSAT/getElements?mode=${suiche}`,
            filter
        );
    }

    getElementSATInit<T>() {
        return this.execute<T>("get", `/adminSAT/getElementsInit`);
    }

    getRealEle<T>(Ninstala) {
        return this.execute<T>(
            "get",
            `/adminSAT/getRealEle?Ninstala=${Ninstala}`
        );
    }

    getPadreEle<T>(Ninstala) {
        return this.execute<T>(
            "get",
            `/adminSAT/getPadreEle?Ninstala=${Ninstala}`
        );
    }

    getGrupCaj<T>(Ncaja) {
        return this.execute<T>("get", `/adminSAT/getGrupCaj?Ncaja=${Ncaja}`);
    }

    delGrup<T>(grup) {
        return this.execute<T>("post", `/adminSAT/delGrup`, grup);
    }

    addGrup<T>(grup) {
        return this.execute<T>("post", `/adminSAT/addGrup`, grup);
    }

    updateGrup<T>(grup) {
        return this.execute<T>("post", `/adminSAT/updateGrup`, grup);
    }

    getAlaEle<T>(Ncajaele) {
        return this.execute<T>(
            "get",
            `/adminSAT/getAlaEle?Ncajaele=${Ncajaele}`
        );
    }

    delAla<T>(grup) {
        return this.execute<T>("post", `/adminSAT/delAla`, grup);
    }

    addAla<T>(grup) {
        return this.execute<T>("post", `/adminSAT/addAla`, grup);
    }

    updateAla<T>(grup) {
        return this.execute<T>("post", `/adminSAT/updateAla`, grup);
    }

    getBoxesEleSAT<T>(Ninstala) {
        return this.execute<T>(
            "get",
            `/adminSAT/getBoxesEle?Ninstala=${Ninstala}`
        );
    }

    deleteElementSAT<T>(ele) {
        return this.execute<T>("post", `/adminSAT/deleteElement`, ele);
    }

    modifyElementSAT<T>(box) {
        return this.execute<T>("post", `/adminSAT/modifyElement`, box);
    }

    addElementSAT<T>(box) {
        return this.execute<T>("post", `/adminSAT/addElement`, box);
    }

    getModelArima<T>(
        box,
        analyzer,
        element,
        TAG,
        start,
        end,
        window,
        aggregation
    ) {
        if (analyzer) {
            if (window && aggregation) {
                return this.execute<T>(
                    "get",
                    `/forecast/model?box=${box}&analyzer=${analyzer}&element=${element}&TAG=${TAG}&start=${start}&end=${end}&window=${window}&aggregation=${aggregation}`
                );
            } else {
                return this.execute<T>(
                    "get",
                    `/forecast/model?box=${box}&analyzer=${analyzer}&element=${element}&TAG=${TAG}&start=${start}&end=${end}`
                );
            }
        } else {
            if (window && aggregation) {
                return this.execute<T>(
                    "get",
                    `/forecast/model?box=${box}&element=${element}&TAG=${TAG}&start=${start}&end=${end}&window=${window}&aggregation=${aggregation}`
                );
            } else {
                return this.execute<T>(
                    "get",
                    `/forecast/model?box=${box}&element=${element}&TAG=${TAG}&start=${start}&end=${end}`
                );
            }
        }
    }

    // Monaguillos
    consumoPeriodo<T>(start, end, data) {
        return this.execute<T>(
            "post",
            `/energy/densidad-periodo?start=${start}&end=${end}`,
            data
        );
    }

    //EuskabeaISO
    consumosISO<T>() {
        return this.execute<T>("get", `report/consumosISO`);
    }

    //Conexion Lotura
    getLotura<T>() {
        return this.execute<T>("get", `datadis/lotura`);
    }
}

export const APIServicePlugin = {
    install: (Vue: typeof _Vue) => {
        Vue.prototype.$api = new APIService();
    }
};
